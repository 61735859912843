.updateMenuTitleWrapper {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  .dragPoint {
    display: flex;
    flex: 1;
  }
}

.customWapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
  overflow-y: auto;
  height: 93vh;
  scroll-behavior: smooth;
}

.updateMenuTitleWrapper .settingInList {
  margin-left: auto;
}

.updateMenuTitleWrapper .listTitle {
  font-size: 16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}

.p-0 {
  padding: 0 !important;
}

.clr-gray {
  color: rgba(0, 0, 0, 0.38) !important;
}

.menu-item-avatar {
  position: relative;
}

.menu-checkbox {
  color: #3A7F59 !important;
}

.menu-checkbox svg {
  width: 1.75em;
  height: 1.75em;
  opacity: 0.2;
}

.menu-checkbox svg path {
  d: path(
    "M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
  ) !important;
}

.menu-checkbox.Mui-checked svg {
  opacity: 1;
}

.footerWapper {
  background: #3A7F59;
  padding: 1.3%;
  color: #fff;
  font-weight: bold;
  margin-top: 2%;
  width: 100%;
}
.subMenuItemWrapper {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1px;
    width: calc(100% - 3.5em);
    background-color: rgba(33, 33, 33, 0.08);
  }
  .subMenuItem {
    box-sizing: border-box;
    .priceLBL {
      position: absolute;
      left: 65px;
      top: 52px;
      span {
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .noDescPriceLBL {
      position: absolute;
      left: 65px;
      top: 32px;
      span {
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .descLBL {
      position: absolute;
      left: 65px;
      top: 32px;
      width: 70%;
      span {
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .menuItemArrow {
      svg {
        width: 0.8em;
        height: 0.8em;
      }
    }
  }
  .dragHandler {
    width: 100%;
  }
}
.footerAction {
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 480px) {
  label.itemName span:last-child {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 65px;
    top: 15px;
  }
}

@media  (max-width: 299px) {
  label.itemName span:last-child {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 65px;
    top: 15px;
  }
  .subMenuItemWrapper .subMenuItem .descLBL {
    width: 55%;
  }
}

@media all and (min-width: 300px) and (max-width: 359px) {
  label.itemName span:last-child {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 65px;
    top: 15px;
  }
  .subMenuItemWrapper .subMenuItem .descLBL {
    width: 60%;
  }
}

@media all and (min-width: 360px) and (max-width: 410px) {
  label.itemName span:last-child {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 65px;
    top: 15px;
  }
  .subMenuItemWrapper .subMenuItem .descLBL {
    width: 68%;
  }
}

@media all and (min-width: 411px) and (max-width: 460px) {
  label.itemName span:last-child {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 65px;
    top: 15px;
  }
  .subMenuItemWrapper .subMenuItem .descLBL {
    width: 73%;
  }
}

@media all and (min-width: 461px) and (max-width: 530px) {
  label.itemName span:last-child {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 65px;
    top: 15px;
  }
  .subMenuItemWrapper .subMenuItem .descLBL {
    width: 75%;
  }
}

@media all and (min-width: 531px) and (max-width: 665px) {
  label.itemName span:last-child {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 65px;
    top: 15px;
  }
  .subMenuItemWrapper .subMenuItem .descLBL {
    width: 80%;
  }
}

@media all and (min-width: 666px) and (max-width: 849px) {
  label.itemName span:last-child {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 65px;
    top: 15px;
  }
  .subMenuItemWrapper .subMenuItem .descLBL {
    width: 85%;
  }
}

@media all and (min-width: 850px) and (max-width: 1024px) {
  label.itemName span:last-child {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 65px;
    top: 15px;
  }
  .subMenuItemWrapper .subMenuItem .descLBL {
    width: 88%;
  }
}

@media all and (min-width: 1025px) {
  label.itemName span:last-child {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 65px;
    top: 15px;
  }
  .subMenuItemWrapper .subMenuItem .descLBL {
    width: 88%;
  }
}