.dashboard-wrapper {
  padding: 50px;

  .dashboard-title {
    font-size: 30px;
  }
}
.yourLink {
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 1.5px;
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.87);
}
.gripMenuD {
  margin-top: 4%;
}
.titleWapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.6);
}

.actionWapper {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-left: 55px;
  margin-right: 55px;
  margin-top: 0.5%;
}
.yourLinkValue {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #5db075;
  box-sizing: border-box;
  border-radius: 9px;
}
ul.actionWapper li label {
  font-size: 13px;
  color: #5db075;
  cursor: pointer;
}

.listingDashboard {
  padding-left: 45px !important;
}
.listingDashboard .MuiListItemSecondaryAction-root {
  right: 0;
}

.listingDashboard li {
  border-bottom: 1px solid rgba(33, 33, 33, 0.6);
}

input.yourLinkValue {
  background: white;
}

@media (min-width: 480px) {
  .appToolBar h6 {
    margin: 0% 0% 0% 8%;
}
}

@media (min-width: 320px) {
  .appToolBar h6 {
    margin: 0% 0% 0% 14%;
}
}

@media (min-width: 768px) {
  .appToolBar h6 {
    margin: 0% 0% 0% 4%;
}
}

@media (min-width: 992px) {
  .appToolBar h6 {
    margin: 0% 0% 0% 3%;
}
}