.previewContainer{
  height: 60vh;
  overflow-y: auto;
  width: 100%;
}

@media all and (max-height: 570px) {
  .previewContainer {
    height: 50vh;
  }
}

@media all and (min-height: 571px) and (max-height: 640px) {
  .previewContainer {
    height: 55vh;
  }
}

@media all and (min-height: 641px) and (max-height: 667px) {
  .previewContainer {
    height: 57vh;
  }
}

@media all and (min-height: 668px) and (max-height: 730px) {
  .previewContainer {
    height: 58vh;
  }
}

@media all and (min-height: 731px) and (max-height: 740px) {
  .previewContainer {
    height: 61vh;
  }
}

@media all and (min-height: 741px) and (max-height: 800px) {
  .previewContainer {
    height: 62vh;
  }
}

@media all and (min-height: 801px) and (max-height: 825px) {
  .previewContainer {
    height: 65vh;
  }
}

@media all and (min-height: 826px) and (max-height: 900px) {
  .previewContainer {
    height: 67vh;
  }
}

@media all and (min-height: 901px) and (max-height: 960px) {
  .previewContainer {
    height: 69vh;
  }
}

@media all and (min-height: 961px) and (max-height: 1024px) {
  .previewContainer {
    height: 71vh;
  }
}

@media all and (min-height: 1025px) and (max-height: 1279px) {
  .previewContainer {
    height: 73vh;
  }
}

@media all and (min-height: 1280px) {
  .previewContainer {
    height: 78vh;
  }
}
