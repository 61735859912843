.itemForm {
    text-align:left;
}

.borderFormControl {
    margin-bottom: 12px !important;
    &.imageFormControl {
        margin-bottom: 25px !important;
    }
    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: calc(100%);
        background-color: rgba(33, 33, 33, 0.08);
    }
}

.borderPartialFormControl {
    margin-bottom: 12px !important;
    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: calc(100% - 3.5em);
        background-color: rgba(33, 33, 33, 0.08);
    }
}

.itemForm input {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
}

.imageFormControl {
    display: block !important;
    padding-bottom: 12px !important;
}

.imageWapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex: 1;
    margin-bottom: 15px;
    label {
        background-color: transparent;
        color: #3A7F59;
        box-shadow: none;
        padding: 0;
        letter-spacing: 1.25px;
    }
}

.previewImage {
    width: 200px;
    height: 100%;
    margin: auto;
    img {
        max-width: 100%;
    }
}