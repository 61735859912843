.blockWapper {
  padding       : 7px 30px;
  background    : #c4c4c4;
  margin        : 0px auto 30px;
  width         : 100%;
  border-radius : 15px;
  text-align    : center;
  text-transform: uppercase;
  cursor: pointer;
}

.previewContainer {
  .RestroLogoWapper {
    .name {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
    }
    .nameOnly {
      width: auto;
  }
  &.float {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    // width: calc(100% + 2rem);
    width: 100%;
    border-bottom: 1px solid #000000;
    background: #ffffff;
    z-index: 9;
    // margin-left: -1rem;
    // margin-right: -1rem;
    margin-top: -5rem;
    padding-top: 1rem;
    > .name {
      margin-bottom: 10px;
    }
    img {
      width: 130px;
    }
    .name {
      .restroName {
        margin: 0;
      }
    }
  }
  }
  .actionWapper svg {
    color: rgba(33, 33, 33, 0.2);
    position: absolute;
    bottom: 8px;
    right: 7px;
}
.centered{
  .actionWapper svg {
    color: rgba(33, 33, 33, 0.2);
    position: inherit;
    bottom: 8px;
    right: 7px;
}
}

div.isHighMarginItemTag.TagRed {
  position: absolute;
  // top: -21%;
  bottom: 100%;
  left: 0%;
  right: 0%;
  color: #ffffff;
  width: auto;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  span {
    background-color: #FF2828;
    display: inline-block;
    padding: 4px 30px;
    min-width: 120px;
  }
  @media (min-width: 320px) {
    bottom: 100%;
  }

  @media (min-width: 480px) {
    bottom: 100%;
  }
}
}

.bottomBarWapper {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-bottom: 1px solid #000000;
  background: #ffffff;
  z-index: 9;
  padding-top: 8px;
  padding-bottom: 8px;

  .bottomBar {
    opacity   : 1;
    transition: all 0.3s ease-in, all 0.6s ease-out;
  }

  &.drawerOpen {
    .bottomBar {
      opacity: 0;
    }
  }
}

.row-container {
  width: 100%;
}

.logo {
  text-transform  : uppercase;
  font-size       : 14px;
  line-height     : 16px;
  text-align      : center;
  letter-spacing  : 1.25px;
  color           : #000000;
  font-weight     : 500;
  // margin-bottom: 50px;
}

.name {
  margin-bottom: 30px;
  text-align   : center;
}

.backWapper {
  position: absolute;
  top     : 9%;
  left    : 3%;
}

.floatItemWapper {
  border: 2px solid #000000;
  background-color: white;
  padding: 12px;
}

.floatContainer {
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-position: center center;
}

.menuBlockTitle {
  font-size     : 20px;
  line-height   : 1;
  align-items   : center;
  text-align    : center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color         : #000000;
  word-wrap: break-word;
}

.sectionTitle {
  font-weight   : 500;
  font-size     : 20px;
  line-height   : 16px;
  text-align    : center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color         : #000000;
  margin-bottom : 40px;
  padding-top: 8px;
  padding-bottom: 5px;
  .sectionDescription {
    margin-bottom: 0;
    text-transform: none;
  }
}

.sectionWapper {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  &.float {
    padding-bottom: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    .sectionTitle {
      border: 2px solid #000000;
      background-color: white;
      padding: 16px 8px;
      margin-top: 24px;
      h3{
        margin-top: 0;
        margin-bottom: 0;
      }
      .sectionDescription {
        margin-bottom: 0;
        text-transform: none;
      }
    }
  }
}

.bottleLogo{
  height: 30px;
  margin: 20px 0 40px;
}

.wineLayoutSectionTitle{
  padding: 2%;
  .sectionTitle{
    word-wrap: break-word;
  }
  h3{
    margin-top: 16px;
    margin-bottom: 0;
  }
  .sectionDescription {
    margin-bottom: 0;
    text-transform: none;
  }
}

.winePriceWrapper{
  word-wrap: break-word;
  width: 50px;
  margin: 0 5px;
}
.wineLayoutItemTitle{
  margin: 0;
  text-align: left;
}

.itemWapper.line {
  border-bottom : 1px solid rgba(33, 33, 33, 0.08);
  position      : relative;
  padding-bottom: 20px;
  margin-bottom : 20px;
  &:last-child {
    margin-bottom: 35px;
  }
  .itemBoxed {
    padding: 2%;
    min-height: 90px;
  }

  // &::after {
  //   content         : "";
  //   position        : absolute;
  //   bottom          : 0;
  //   right           : 0;
  //   height          : 1px;
  //   width           : calc(100% - 10px);
  //   background-color: rgba(33, 33, 33, 0.08);
  // }

  .header {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    margin-bottom  : 10px;

    .itemTitle {
      font-size     : 16px;
      line-height   : 24px;
      letter-spacing: 0.15px;
      color         : rgba(0, 0, 0, 0.87);
      margin        : 0;
      font-weight   : 400;
      text-align: left;
    }

    .priceWapper {
      font-size     : 12px;
      line-height   : 16px;
      text-align    : right;
      letter-spacing: 0.4px;
      color         : rgba(0, 0, 0, 0.6)
    }
  }

  .details {
    .descWapper {
      display        : flex;
      align-items    : flex-start;
      justify-content: space-between;

      p {
        font-size     : 14px;
        line-height   : 20px;
        letter-spacing: 0.25px;
        color         : rgba(0, 0, 0, 0.6);
        margin        : 0;
        width         : auto;
        padding-right: 15px;
        text-align: left;
        white-space: pre-line;
      }
    }
    .actionWapper {
      margin-left: auto;
      margin-right: 0;
      justify-content: flex-end;
      span {
        cursor: pointer;
      }

      span.isLiked i {
        color: red !important;
      }

      i {
        color     : rgba(171, 171, 171, 0.9);
        position: absolute;
        bottom: 28px;
        right: 7px;
      }
    }
  }
}

.itemWapper.linePhoto {
  position      : relative;
  padding-bottom: 0;
  margin-bottom : 20px;
  padding-left  : 0;
  border-radius: 3px;
  background-color: white;
  border: 1px solid #CDC9CD;

  &:last-child {
    margin-bottom: 35px;
  }

  .itemBoxed {
    display: flex;
    width: 100%;
    padding: 0;


    .textWapper {
      margin-left: 15px;
      width: 100%;;
      padding: 2px 0;
  }
  img {
    width: 150px;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
  }
  .itemImageWapper{
    min-height: 110px;
    border-right: 1px solid #CDC9CD;
}
  }

  // &::after {
  //   content         : "";
  //   position        : absolute;
  //   bottom          : 0;
  //   right           : 0;
  //   height          : 1px;
  //   width           : calc(100% - 10px);
  //   background-color: rgba(33, 33, 33, 0.08);
  // }

  .header {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    margin-bottom  : 5px;
    width: 100%;

    .itemTitle {
      font-size     : 16px;
      line-height   : 24px;
      letter-spacing: 0.15px;
      color         : rgba(0, 0, 0, 0.87);
      margin        : 0;
      font-weight   : 400;
      text-align: left;
    }

    .priceWapper {
      font-size     : 12px;
      line-height   : 16px;
      text-align    : right;
      letter-spacing: 0.4px;
      color         : rgba(0, 0, 0, 0.6);
      padding-right: 7px;
    }
  }

  .details {
    .descWapper {
      display        : flex;
      align-items    : flex-start;
      justify-content: space-between;

      p {
        font-size     : 14px;
        line-height   : 20px;
        letter-spacing: 0.25px;
        color         : rgba(0, 0, 0, 0.6);
        margin        : 0;
        width         : auto;
        padding-right : 15px;
        text-align: left;
        white-space: pre-line;
      }
    }
    .actionWapper {
      margin-left: auto;
      margin-right: 0;
      justify-content: flex-end;
      span {
        cursor: pointer;
      }

      span.isLiked i {
        color: red !important;
      }

      i {
        color     : rgba(33, 33, 33, 0.2);
        margin-top: 0;
        right: 7px;
        bottom: 7px;
        position: absolute;;
      }
    }
  }
}

.itemWapper.float {
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 30px;
  .itemBoxed {
      padding: 2%;
      min-height: 90px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .itemTitle {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-weight: 400;
    text-align: left;
  }
  .priceWapper {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.4px;
    min-width: 20%;
    color: rgba(0, 0, 0, 0.6);
  }
  .details {
    .descWapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      p {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: rgba(0, 0, 0, 0.6);
          margin: 0;
          width: auto;
          padding-right: 15px;
          text-align: left;
          white-space: pre-line;
      }
      .actionWapper {
        span {
          cursor: pointer;
        }
        i {
          color: rgba(33, 33, 33, 0.2);
          position: absolute;
          bottom: 28px;
          right: 7px;
        }
      }
    }
  }
}

.itemWapper.isHighMarginBOX {
  border: 2px solid #FF2828;
}

.itemWapper.centered {
  border-bottom : 1px solid rgba(33, 33, 33, 0.08);
  position       : relative;
  padding-bottom : 20px;
  margin-bottom  : 20px;
  display        : flex;
  align-items    : center;
  flex-direction : column;
  justify-content: center;

  &:last-child {
    margin-bottom: 35px;
  }

  .itemBoxed {
    width: 100%;
  }

  // &::after {
  //   content         : "";
  //   position        : absolute;
  //   bottom          : 0;
  //   right           : 0;
  //   height          : 1px;
  //   width           : calc(100% - 10px);
  //   background-color: rgba(33, 33, 33, 0.08);
  // }

  .header {
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-bottom  : 10px;

    .itemTitle {
      font-size     : 16px;
      line-height   : 24px;
      letter-spacing: 0.15px;
      color         : rgba(0, 0, 0, 0.87);
      margin        : 0;
      font-weight   : 400;
    }

    .priceWapper {
      font-size     : 12px;
      line-height   : 16px;
      text-align    : right;
      letter-spacing: 0.4px;
      color         : rgba(0, 0, 0, 0.6);
    }
  }

  .details {
    .descWapper {
      display        : flex;
      justify-content: center;
      flex-direction : column;
      align-items    : center;

      p {
        font-size     : 14px;
        line-height   : 20px;
        letter-spacing: 0.25px;
        color         : rgba(0, 0, 0, 0.6);
        margin        : 0;
        width         : auto;
        text-align    : center;
        white-space: pre-line;
      }
    }
    .actionWapper {
      margin-left: auto;
      margin-right: 0;
      justify-content: center;
      position: inherit;
      span {
        cursor: pointer;
      }

      span.isLiked i {
        color: red !important;
      }

      i {
        color   : rgba(33, 33, 33, 0.2);
        position: absolute;
        right   : 0;
        bottom  : 18px;
      }
    }
  }
  &.isHighMarginBOX {
    border: 2px solid #FF2828;
  }
}

.itemWapper.centeredPhoto {
  border-bottom : 1px solid rgba(33, 33, 33, 0.08);
  position       : relative;
  padding-bottom : 20px;
  margin-bottom  : 20px;
  display        : flex;
  align-items    : center;
  flex-direction : column;
  justify-content: center;

  &:last-child {
    margin-bottom: 35px;
  }

  // &::after {
  //   content         : "";
  //   position        : absolute;
  //   bottom          : 0;
  //   right           : 0;
  //   height          : 1px;
  //   width           : calc(100% - 10px);
  //   background-color: rgba(33, 33, 33, 0.08);
  // }

  .header {
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-bottom  : 5px;
    flex-direction : column;

    .itemTitle {
      font-size     : 16px;
      line-height   : 24px;
      letter-spacing: 0.15px;
      color         : rgba(0, 0, 0, 0.87);
      margin        : 0;
      font-weight   : 400;
    }

    .priceWapper {
      font-size     : 12px;
      line-height   : 16px;
      text-align    : right;
      letter-spacing: 0.4px;
      color         : rgba(0, 0, 0, 0.6);
    }

    .itemImageWapper {
      width : 40px;
      height: 40px;

      img {
        width        : 100%;
        height       : auto;
        border-radius: 50%;
      }
    }
  }

  .details {
    .descWapper {
      display        : flex;
      justify-content: center;
      flex-direction : column;
      align-items    : center;

      p {
        font-size     : 14px;
        line-height   : 20px;
        letter-spacing: 0.25px;
        color         : rgba(0, 0, 0, 0.6);
        margin        : 0;
        width         : auto;
        text-align    : center;
        white-space: pre-line;
      }

    }
    .actionWapper {
      margin-left: auto;
      margin-right: 0;
      justify-content: center;
      position: inherit;
      span {
        cursor: pointer;
      }

      span.isLiked i {
        color: red !important;
      }

      i {
        color   : rgba(33, 33, 33, 0.2);
        position: absolute;
        right   : 0;
        bottom  : 18px;
      }
    }
  }
}

.likeItem {
  display        : flex;
  justify-content: center;
  color          : #fff;
  cursor         : pointer;

  .textWapper {
    font-size     : 14px;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    font-weight   : normal;
    margin-left   : 7px;
  }
}

ul.likeListWrapper {
  list-style: none;
  padding   : 0;
  margin    : 0;

  li {
    width         : 100%;
    padding       : 15px;
    color         : rgba(0, 0, 0, 0.6);
    font-size     : 14px;
    font-weight   : 500;
    letter-spacing: .1px;

    i {
      color       : rgba(0, 0, 0, 0.6);
      color       : rgba(0, 0, 0, 0.6);
      font-size   : 18px;
      margin-right: 25px;
    }
  }
}

.drawerHeader {
  display        : flex;
  justify-content: center;
  color          : #fff;
  background     : #3A7F59;
  padding        : 10px;
  cursor         : pointer;

  .drawerHeaderTextWapper {
    margin-left: 7px;
  }
}

.counter {
  font-size     : 14px;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  font-weight   : normal;
  position      : relative;

  .heartCount {
    position : absolute;
    color    : rgba(0, 0, 0, 0.5);
    transform: translate(-50%, -50%);
    left     : 50%;
    // top: 50%;
    top      : 45%;
    font-size: 12px;
  }

  i {
    font-size: 20px;
  }

  .drawerHeaderTextWapper {
    font-size     : 14px;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    font-weight   : normal;
    margin-left   : 7px;
  }
}

.drawerWrapperMain div:nth-child(2) {
  border-radius: 0;
}

.drawerWrapperMain div:nth-child(2)>div {
  padding: 0;
}

.drawerWrapperMain div:nth-child(2)>div>div {
  height: unset;
}

.isHighLighted {
  background: #eee;
}

@media (min-width: 320px) {
  .wapperMain {
    width: 100%;
  }

  .fixBox {
    width: 250px;
  }
}

@media (min-width: 480px) {
  .fixBox {
    width: 400px;
  }
}

@media (min-width: 768px) {
  .wapperMain {
    width: 65%;
  }

  .fixBox {
    width: 600px;
  }
}

@media (min-width: 992px) {
  .fixBox {
    width: 780px;
  }
}
