.premiumBannerContainer {
  padding: 6px 0;
  margin: 56px 0 8px 0;
  background-color: rgba(58, 127, 89, 0.38)
}

@media all and (min-width: 600px) {
  .premiumBannerContainer {
    margin: 64px 0 8px 0;
  }
}
