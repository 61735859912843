.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

.form-wrapper {
  padding: 50px;
  text-align: center;

  .title {
    font-size: 30px;
    padding: 30px;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 300px;
    margin: 0 auto;
    padding: 10px 20px;

    label {
      min-width: 100px;
    }

    input {
      flex: 1;
    }
  }
}