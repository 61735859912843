img {
  max-width : 100%;
  max-height: 100%;
}

.menu {
  flex-direction: column;
  height        : 100vh;
  padding-left  : 25px !important;
  padding-right : 25px !important;
}

.previewContainer .previewMenuBuilder{
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.menu.previewMenuBuilder .RestroLogoWapper .name.MainMenu {
  margin: 0;
}

.restaurantTitle {
  max-height: 20%;
  padding   : 20px;
}

.container {
  padding: 10px;
}

.menuSection {
  display         : flex;
  flex-direction  : column;
  padding         : 10px;
  margin          : 10px;
  background-color: beige;
  border-style    : solid;
  border-color    : black;
  border-width    : 5px;
  border-radius   : 50px;
}

.description {
  padding: 10px;
}